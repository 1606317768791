export function isWistiaVideoPlaying() {
	let isVideoPlaying = false;

	function wistiaVideo() {
		const videoID = document.querySelector(".js-wistia-video-id").textContent;
		const wistiaSrc = `https://fast.wistia.net/embed/iframe/${videoID}?videoFoam=true`;

		if (wistiaSrc === "https://fast.wistia.net/embed/iframe/?videoFoam=true") {
			return;
		}

		const wistiaIframe = document.querySelector(".js-wistia-video");
		wistiaIframe.setAttribute("src", wistiaSrc);

		window._wq = window._wq || [];
		const videoTitles = document.querySelector(".js-video");
		videoTitles.addEventListener("click", function (ev) {
			videoTitles.classList.add("hide");
			playVideo();
		});
		function playVideo() {
			let playStatus;
			_wq.push({
				id: "_all",
				onReady: function (video) {
					video.play();
					video.bind("play", function () {
						playStatus = true;
						videoPlayStatus(playStatus);
					});
					video.bind("pause", function () {
						playStatus = false;
						videoPlayStatus(playStatus);
					});
				}
			});
		}
	}
	wistiaVideo();

	function videoPlayStatus(status) {
		isVideoPlaying = status;
	}
	return isVideoPlaying;
}
