import { isDialogSupported } from "../_script.js";
import { copyToClipboard } from "../../../assets/js/utilities.js";
import { toggleAmbientVideo } from "../../../components/hero/_video.js";

export function modalYoutube() {
	const youtubeModal = document.querySelector("[youtube-modal]");
	const youtubeModalClose = document.querySelector("[youtube-modal-close]");
	const youtubeLinks = document.querySelectorAll("a[href*='youtube.com']:not([nomodal])");
	const youtubeIframe = document.querySelector("[youtube-modal-iframe]");
	const unsetSrc = "";

	// Function - check url for youtube
	function checkUrlForYoutube() {
		let querystring = new URLSearchParams(window.location.search);
		let modalParam = querystring.get("modal");
		let lightboxParam = querystring.get("lightbox");
		let youtubeSrc = "";

		// Check if either modal or lightbox parameter contains "youtube.com"
		if (modalParam && modalParam.includes("youtube.com")) {
			youtubeSrc = modalParam;
		} else if (lightboxParam && lightboxParam.includes("youtube.com")) {
			youtubeSrc = lightboxParam;
		}
		// If youtubeSrc has been set, decode and open the YouTube modal
		if (!youtubeSrc) return;
		youtubeSrc = decodeURIComponent(youtubeSrc);
		openYoutubeModal(youtubeSrc);
	}
	checkUrlForYoutube();

	// Event : check for youtube links
	function checkLinksForYoutube() {
		youtubeLinks.forEach((item) => {
			let href = item.getAttribute("href");
			if (href.includes("@nathab")) {
				return;
			}
			item.addEventListener("click", (event) => {
				event.preventDefault();
				let youtubeSrc = item.getAttribute("href");
				youtubeSrc = youtubeSrc.replace(/(^.*\?lightbox=)/i, "");
				youtubeSrc = youtubeSrc.replace(/(^.*\?modal=)/i, "");
				if (event.shiftKey) {
					let shiftClickUrl = `https://${window.location.hostname}${window.location.pathname}?modal=${youtubeSrc}`;
					copyToClipboard(shiftClickUrl);
				}
				openYoutubeModal(`${youtubeSrc}`);
			});
		});
	}
	checkLinksForYoutube();

	// Function - Open Youtube Modal
	function openYoutubeModal(src) {
		setSrc(src);
		if (youtubeModal.hasAttribute("open")) return;
		if (!isDialogSupported()) {
			youtubeModal.setAttribute("open", "");
		} else {
			youtubeModal.showModal();
		}
		sessionStorage.setItem("modalOpen", true);
		toggleAmbientVideo(false);
	}

	// Function - Close Youtube Modal
	function closeYoutubeModal() {
		setSrc(unsetSrc);
		if (!isDialogSupported()) {
			youtubeModal.removeAttribute("open");
		} else {
			youtubeModal.close();
		}
	}

	// Function - Set Youtube Src
	function setSrc(src) {
		youtubeIframe.setAttribute("src", src);
	}

	// Event : close event for when modal is open
	document.addEventListener("click", function (event) {
		if (youtubeModal.contains(event.target) || (event.target === youtubeModal && youtubeModal.open)) {
			closeYoutubeModal();
		}
	});

	// Event : close button click
	youtubeModalClose.addEventListener("click", () => {
		closeYoutubeModal();
	});

	// Event : if escape key is pressed (but captures all close events)
	youtubeModal.addEventListener("close", () => {
		closeYoutubeModal();
		sessionStorage.setItem("modalOpen", false);
		toggleAmbientVideo(true);
	});
}
