import { pushEvent, pushEventFacebook, pushEventBing, getBrowserDevice, saveUserCountryToLocalStorage } from "../../../assets/js/utilities.js";
import { callOpenModalAndVideoFunctions } from "../../olark/_script.js";
import { initSmarty } from "../../../assets/js/smartypants.js";
import { initJsFormatPhone, initJsFormatName, setOptsOnMarketoForm, isFormValid, isNewsletterChecked, setNewsletterCheckbox, pushUtmToMarketoForm, toggleButtonDisabled, isThisSpam } from "../../../assets/js/global-form-functions.js";
import { isChatOpen } from "../../olark/_script.js";
import { isSMSOptinChecked, setSMSOptsOnMarketoForm } from "../../../assets/js/global-form-functions.js";
import { toggleAmbientVideo } from "../../../components/hero/_video.js";

export function ebookCTAs() {
	let marketoCampaignName;
	let ebookUrl;

	// Function: do stuff to ebook modal
	async function eBookModal(id) {
		let campaignID = id;
		await saveUserCountryToLocalStorage();
		let userCountry = localStorage.getItem("country") || "unknown";

		let eBook = new Object();
		eBook.formName = "#form-ebook";
		eBook.newsletter = false;
		eBook.firstInput = document.querySelector("#form-ebook-first_name");
		eBook.smsElementParent = document.querySelector(".js-sms-checkbox-ebook");
		eBook.smsElement = document.querySelector("[js-ebook-sms]");
		eBook.phoneElementInput = document.querySelector("[js-ebook-phone-toggle]");
		eBook.phoneElementLabel = document.querySelector('[for~="form-ebook-phone"]');
		eBook.phoneElementLabelRequired = document.querySelector('[for~="form-ebook-phone"] span');

		initJsFormatPhone();
		initJsFormatName();

		const campaignData = {
			tu18oaqav9qkl5yfxsyv: {
				name: "Fundamentals of Nature Photography",
				url: "https://issuu.com/natural-habitat-adventures/docs/nature_photography_fundamentals_ebook?fr=sZDYxNTYwMDUyMTM"
			},
			voupy8wdnwblul6vjl1y: {
				name: "Fundamentals of Nature Photography",
				url: "https://issuu.com/natural-habitat-adventures/docs/nature_photography_fundamentals_ebook?fr=sZDYxNTYwMDUyMTM"
			},
			urqd4tb4mtflhk3tzox6: {
				name: "Polar Bear Photography Guide",
				url: "https://issuu.com/natural-habitat-adventures/docs/polar_bear_photography_guide"
			},
			wjpuhgvxuo1nxcgrgfam: {
				name: "Polar Bear Photography Guide",
				url: "https://issuu.com/natural-habitat-adventures/docs/polar_bear_photography_guide"
			},
			op07bfqilfpg9akgzxvn: {
				name: "Northern Lights Photography Guide",
				url: "https://issuu.com/natural-habitat-adventures/docs/northern-lights-photography-guide"
			},
			quthwhoxff0adv3mp0fs: {
				name: "Galapagos Brochure",
				url: "https://issuu.com/natural-habitat-adventures/docs/feb_galapagos_brochure"
			},
			jnurbdjfkiftde7yhpej: {
				name: "Polar Bear Brochure",
				url: "https://issuu.com/natural-habitat-adventures/docs/mar_polar_bear_brochure"
			},
			h73zz2yn0ttzbpmquk3y: {
				name: "Australia Brochure",
				url: "https://issuu.com/natural-habitat-adventures/docs/jun_australia_brochure"
			},
			broursnxugepvtqqpdqr: {
				name: "World's Best Adventures For Every Season eBook",
				url: "https://issuu.com/natural-habitat-adventures/docs/nathab-ebook-adventureseveryseason?fr=sNDc3ZjYzMDAxMDM"
			},
			pmsnrvg18fxjsbhigwf3: {
				name: "Africa's Big 5 eBook",
				url: "https://issuu.com/natural-habitat-adventures/docs/nathab-ebook-africasbigfive?fr=sNWVmNjYzMDAxMDM"
			},
			ql9emap4ll16mpusswat: {
				name: "Rainforest Adventures eBook",
				url: "https://issuu.com/natural-habitat-adventures/docs/nathab-ebook-rainforestadventures?fr=sZjk1MjYzMDAxMDM"
			},
			yp4ppkps3xnxuq0gob59: {
				name: "Iceland Greenland Brochure",
				url: "https://issuu.com/natural-habitat-adventures/docs/nat-hab-iceland-greenland-brochure-2025-2026?fr=sZmUxZjc2OTAwNDU"
			},
			gnzxsrszzlgfpwhcg5tu: {
				name: "Digital Catalog",
				url: "https://issuu.com/natural-habitat-adventures/docs/2025-26_travel-catalog_nat-hab?fr=sYmI5YTc2OTAwNDU"
			},
			csgufgguzlblnto2ltii: {
				name: "Peru Brochure",
				url: "https://issuu.com/natural-habitat-adventures/docs/nov_peru_brochure?fr=xKAE9_zU1NQ"
			}
		};

		if (campaignData[campaignID]) {
			marketoCampaignName = campaignData[campaignID].name;
			ebookUrl = campaignData[campaignID].url;
		}

		sessionStorage.setItem("modalOpen", true);

		// pause ambient video when modal is opened
		toggleAmbientVideo(false);

		pushEvent("Gated Content", "eBook", "Open");

		setTimeout(() => {
			eBook.firstInput.focus();
		}, 1000);

		// if eBook shown on page, prevent itinerary (enews) modal from auto popping
		let pageVisits = Cookies.get("itin");
		if (pageVisits === 1) {
			Cookies.set("itin", 0);
		}

		//check eNews box for users in US, India, and Australia
		if (userCountry === "US") {
			setNewsletterCheckbox();
			initSmarty(eBook.formName);
			eBook.smsElementParent.classList.remove("hide");
			smsCheckbox();
		} else if (userCountry === "IN" || userCountry === "AU") {
			setNewsletterCheckbox();
		}

		// Event - When SMS checkbox changes do stuff
		function smsCheckbox() {
			eBook.smsElement.addEventListener("input", function () {
				if (this.checked) {
					eBook.phoneElementInput.setAttribute("required", "");
					eBook.phoneElementLabel.setAttribute("ebook-form-label", "required");
					eBook.phoneElementLabelRequired.classList.remove("hide");
				} else {
					eBook.phoneElementInput.removeAttribute("required");
					eBook.phoneElementLabel.setAttribute("ebook-form-label", "");
					eBook.phoneElementInput.classList.remove("js-invalid");
					eBook.phoneElementLabelRequired.classList.add("hide");
				}
			});
		}

		// EVENT - Handle submit of required form
		document.querySelector(".js-ebook-submit-button").addEventListener("click", function (e) {
			e.preventDefault();
			postFormEbook();
		});

		function copyEbookFieldsToMarketoForm() {
			let fieldsToCopyArray = document.querySelectorAll(`${eBook.formName} [mrkto-field]`);
			fieldsToCopyArray.forEach((field) => {
				let mrktoFieldSelector = field.getAttribute("mrkto-field");
				if (field.value) {
					document.querySelector(mrktoFieldSelector).value = field.value;
				}
			});
		}

		function postFormEbook() {
			let retryLimit = 3,
				retryAttempt = 0;
			// Validate form
			let isValid = isFormValid(eBook.formName);
			if (isValid === false) {
				return;
			}
			// Check for spam
			if (isThisSpam(eBook.formName) === true) {
				return;
			}
			toggleButtonDisabled(document.querySelector(".js-ebook-submit-button"));
			// Check if marketo form has been loaded, if not retry with limits
			if (checkMarketoForm() === false && retryAttempt < retryLimit) {
				initMarketoForm(function () {
					retryAttempt++;
					postFormEbook();
				});
			}
			MktoForms2.whenReady(function () {
				let optins = "";

				pushUtmToMarketoForm(); // function lives in global
				document.querySelector("#requestedAt").value = document.URL;
				document.querySelector("#LeadSource").value = "www.nathab.com";
				document.querySelector("#sourceDetail").value = "eBook";
				document.querySelector("#iseBookDownload").value = "true";
				document.querySelector("#recentConversionAction").value = "eBook Download";
				document.querySelector("#referringWebsite").value = utmObject.referringWebsite || "";
				document.querySelector("#recenteBookRequest").value = marketoCampaignName;
				document.querySelector("#recenteBookURL").value = ebookUrl;
				copyEbookFieldsToMarketoForm(eBook.formName);

				if (isNewsletterChecked(eBook.formName) === true) {
					document.querySelector("#isEmailSignup").value = true;
					optins += " eNews";
					setOptsOnMarketoForm();
				}

				if (isSMSOptinChecked(eBook.formName) === true) {
					document.querySelector("#isSMSSignup").value = true;
					optins += " SMS";
					setSMSOptsOnMarketoForm();
				}

				// stupid edge case just to get a comma in the string
				optins = optins.replace("eNews SMS", "eNews, SMS");

				optins = optins || " no optins"; // if empty string, set to no optins
				let pushEventString = `Submitted w/${optins}`;

				pushEvent("Gated Content", "eBook", pushEventString);

				marketoSubmitFormEbook();
				pushEventFacebook();
				pushEventBing();

				// click hidden OptinMonster button to advance to success screen
				setTimeout(() => {
					document.querySelector(".js-ebook-goto-success button").click();
				}, 2100);
			});
		}

		function marketoSubmitFormEbook() {
			if (checkMarketoForm() === false) {
				pushEvent("Marketo", "Form", "Not Loaded during marketoSubmitForm()");
			}
			MktoForms2.whenReady(function (form) {
				form.submit();
			});
		}

		// resume ambient video play when modal is closed
		document.addEventListener("om.Campaign.afterClose", function (event) {
			sessionStorage.setItem("modalOpen", false);
			toggleAmbientVideo(true);
		});
	}

	// Function: if campaign is removed above due to open chat, etc. remove these OM classes to make their overlay go away. Worked with OM tech and this was the only solution. Boo Monsty.
	function removeOmOverlay() {
		setTimeout(function () {
			try {
				document.querySelector("html").classList.remove("om-position-popup");
				document.querySelector("body").classList.remove("om-effect-overlay");
			} catch (error) {}
		}, 1500);
	}

	// Event: this runs right before a campaign screen is visible and for every view (yes/no, optin, success)
	document.addEventListener("om.Campaign.load", (event) => {
		let omCampaign = document.querySelector("#om-" + event.detail.Campaign.id);

		// Check if any other modals are open or if inline Wistia video is playing
		if (event.detail.Campaign.view === "optin") {
			if (window.location.pathname.includes("/blog/")) {
				eBookModal(event.detail.Campaign.id);
			} else {
				let isModalOpenOrVideoPlaying = callOpenModalAndVideoFunctions();

				if (isModalOpenOrVideoPlaying || isChatOpen()) {
					omCampaign.remove();
					removeOmOverlay();
				} else eBookModal(event.detail.Campaign.id);
			}
		}
		// Event: when success screen loads, click on ebook link for user
		if (event.detail.Campaign.view === "success") {
			document.querySelector(".js-ebook-goto-url").click();
		}

		// Event: listen for click on bottom nav on mobile to close campaign
		let browserDevice = getBrowserDevice();

		if (browserDevice === "mobile") {
			const mobileMenuLinks = document.querySelectorAll("[nav-site-links--mobile] label");
			mobileMenuLinks.forEach((link) => {
				link.addEventListener("click", function () {
					if (omCampaign) {
						omCampaign.remove();
						removeOmOverlay();
					}
				});
			});
		}
	});
}
