let urlParamstoCookiesList = ["gclid", "msclkid", "fbclid", "utmterm", "cid"];

export function setOptsOnMarketoForm() {
	document.querySelector("#optInNHA").value = true;
	document.querySelector("#optInWebinar").value = true;
	document.querySelector("#optInStories").value = true;
	document.querySelector("#optInSurveys").value = true;
	document.querySelector("#optInTripDrips").value = true;
	document.querySelector("#optInSalesperson").value = true;
}

export function setSMSOptsOnMarketoForm() {
	document.querySelector("#optInSMS").value = true;
}

export function isFormValid(formName) {
	var invalidCount = $(formName).find(":invalid").length;
	if (invalidCount > 0) {
		$(formName).find(":invalid").addClass("js-invalid");
		return false;
	} else {
		return true;
	}
}

export function isNewsletterChecked(formName) {
	return document.querySelector(`${formName} ${formName}-requestNewsletter`).checked;
}

export function isSMSOptinChecked(formName) {
	return document.querySelector(`${formName} ${formName}-Optin-SMS`).checked;
}

// Add event listener to name input to remove special characters
export function initJsFormatName() {
	const nameInputs = document.querySelectorAll("[js-format-name]");

	if (!nameInputs) return;

	nameInputs.forEach((input) => {
		input.addEventListener("blur", () => {
			encodeSpecialChars(input);
		});
	});
}

function encodeSpecialChars(input) {
	let inputValue = input.value;

	if (inputValue.match(/&/g)) input.value = inputValue.replace(/&/g, "and");

	if (inputValue.match(/[!@#$%^*(),.?":{}|<>]/g)) input.value = inputValue.replace(/[!@#$%^*(),.?":{}|<>]/g, "");
}

// Add event listener to phone input
export function initJsFormatPhone() {
	// select it
	const phoneInputs = document.querySelectorAll("[js-format-phone]");

	// check it
	if (!phoneInputs) return;

	// handle it
	phoneInputs.forEach((input) => {
		input.addEventListener("blur", () => {
			formatAndValidatePhoneNumber(input);
		});
	});
}

// Format and validate phone numbers
export function formatAndValidatePhoneNumber(phoneInput) {
	let phoneNumber = phoneInput.value.replace(/\D/g, "");

	if (phoneNumber.length >= 3) {
		phoneNumber = "(" + phoneNumber.substr(0, 3) + ") " + phoneNumber.substr(3);
		if (phoneNumber.length > 9) {
			phoneNumber = phoneNumber.slice(0, 9) + "-" + phoneNumber.slice(9);
		}
	}

	phoneInput.value = phoneNumber;

	if (phoneInput.hasAttribute("required")) {
		const phonePattern = /^\(\d{3}\) \d{3}-\d{4}$/;
		if (!phonePattern.test(phoneNumber)) {
			phoneInput.classList.add("js-invalid");
			phoneInput.setCustomValidity("invalid");
		} else {
			phoneInput.classList.remove("js-invalid");
			phoneInput.setCustomValidity("");
		}
	} else {
		phoneInput.classList.remove("js-invalid");
		phoneInput.setCustomValidity("");
	}
}

export function setNewsletterCheckbox() {
	let listOfCheckboxes = document.querySelectorAll(".js-set-by-country");
	listOfCheckboxes.forEach((element) => {
		element.setAttribute("checked", "checked");
	});
}

// BUG? This may break if two ctas are used without a page refresh
// Also this code is confusing especially the [].forEach .... is this using lodash or vanilla js
export function specificTarget(inputID, labelFor, listName, targetName) {
	var targetList = document.querySelectorAll(`${inputID}, ${labelFor}, ${listName}`);
	[].forEach.call(targetList, function (target) {
		if ($(target).is('[list^="replace"]') && $(target).is('[id^="replace"]')) {
			$(target).attr("list", $(target).attr("list").replace("replace", `${targetName}`));
			$(target).attr("id", $(target).attr("id").replace("replace", `${targetName}`));
		} else if ($(target).is('[id^="replace"]')) {
			$(target).attr("id", $(target).attr("id").replace("replace", `${targetName}`));
		} else if ($(target).is('[for^="replace"]')) {
			$(target).attr("for", $(target).attr("for").replace("replace", `${targetName}`));
		}
	});
}

export function mktoButtonClick() {
	$(".mktoButton").first().click();
}

export function pushUtmToMarketoForm() {
	try {
		document.querySelector("#utmcampaign").value = utmObject.utmcampaign || "";
		document.querySelector("#utmcontent").value = utmObject.utmcontent || "";
		document.querySelector("#utmmedium").value = utmObject.utmmedium || "";
		document.querySelector("#utmsource").value = utmObject.utmsource || "";
		document.querySelector("#utmterm").value = utmObject.utmterm || "";

		$("#gclid").val(utmObject.gclid || "");
		$("#fbclid").val(utmObject.fbclid || "");
		$("#msclkid").val(utmObject.msclkid || "");
		$("#cid").val(utmObject.cid || "");
	} catch (error) {
		console.error("Error: ", error);
	}
}

export function initMarketoFormBasics(formName) {
	// First Name
	document.querySelector("#LastName").value = document.querySelector(`${formName} ${formName}-last_name`).value;
	document.querySelector("#FirstName").value = document.querySelector(`${formName} ${formName}-first_name`).value;
	document.querySelector("#Email").value = document.querySelector(`${formName} ${formName}-email`).value;
	document.querySelector("#LeadSource").value = "www.nathab.com";

	// Set Click IDs from Cookie, or param if cookie is missing
	urlParamstoCookiesList.forEach(function (parameter) {
		$(`#${parameter}`).val(utmObject[parameter]);
	});

	// Set Recent Conversion Action
	document.querySelector("#recentConversionAction").value = document.querySelector(`${formName} [rca~="value"]`).value;

	// ReferredAt = document.URL;
	document.querySelector("#requestedAt").value = document.URL;

	// Referring Website from cookie
	$("#referringWebsite").val(utmObject.referringWebsite || "");
}

export function initMarketoFormCatalogBasic(formName) {
	document.querySelector("#Address").value = document.querySelector(`${formName} ${formName}-pdfRequestHomeStreet`).value;
	document.querySelector("#addressLine2").value = document.querySelector(`${formName} ${formName}-pdfRequestHomeStreet2`).value;
	document.querySelector("#City").value = document.querySelector(`${formName} ${formName}-pdfRequestHomeCity`).value;
	document.querySelector("#State").value = document.querySelector(`${formName} ${formName}-pdfRequestHomeState`).value;
	document.querySelector("#PostalCode").value = document.querySelector(`${formName} ${formName}-pdfRequestHomeZIP`).value;
	var countryValue = $(formName).find(`${formName}-pdfRequestHomeCountry`).val();
	document.querySelector("#Country").value = countryValue;
	if (document.querySelector(`${formName} ${formName}-informationRequestPhone` != null)) {
		document.querySelector("#Phone").value = document.querySelector(`${formName} ${formName}-informationRequestPhone`).value;
	}
}

export function initMarketoFormCatalogExtra(formName) {
	document.querySelector("#temp8CatReferralSource").value = document.querySelector(`${formName} ${formName}-find-nathab`).value;
	document.querySelector("#temp9CatReferralName").value = document.querySelector(`${formName} ${formName}-friend-referral`).value;
	document.querySelector("#temp10CatPartySize").value = document.querySelector(`${formName} ${formName}-party-size`).value;
	document.querySelector("#temp11CatBudget").value = document.querySelector(`${formName} ${formName}-trip-budget`).value;
	document.querySelector("#temp12CatAvailability").value = document.querySelector(`${formName} ${formName}-seasonYearInfo`).value;
	document.querySelector("#temp13CatTripSpecifics").value = document.querySelector(`${formName} ${formName}-itinRequest`).value;

	if (document.querySelector(`${formName} ${formName}-company_name` != null)) {
		document.querySelector("#Company").value = document.querySelector(`${formName} ${formName}-company_name`).value;
	}

	// Previous traveled checkboxes
	if (document.querySelector(`${formName} ${formName}-previously-traveled-africa`).checked) {
		document.querySelector("#previousTravelinAfrica").value = true;
	}
	if (document.querySelector(`${formName} ${formName}-previously-traveled-alaska`).checked) {
		document.querySelector("#previousTravelinAlaska").value = true;
	}
	if (document.querySelector(`${formName} ${formName}-previously-traveled-antarctica`).checked) {
		document.querySelector("#previousTravelinAntarctica").value = true;
	}
	if (document.querySelector(`${formName} ${formName}-previously-traveled-arctic`).checked) {
		document.querySelector("#previousTravelinArctic").value = true;
	}
	if (document.querySelector(`${formName} ${formName}-previously-traveled-asia`).checked) {
		document.querySelector("#previousTravelinAsia").value = true;
	}
	if (document.querySelector(`${formName} ${formName}-previously-traveled-central-america`).checked) {
		document.querySelector("#previousTravelinCentralAmerica").value = true;
	}
	if (document.querySelector(`${formName} ${formName}-previously-traveled-galapagos`).checked) {
		document.querySelector("#previousTravelinGalapagos").value = true;
	}
	if (document.querySelector(`${formName} ${formName}-previously-traveled-south-america`).checked) {
		document.querySelector("#previousTravelinSouthAmerica").value = true;
	}

	// Interests Checkboxes
	if (document.querySelector(`${formName} ${formName}-destination-polar-bears`).checked) {
		document.querySelector("#interestinPolarBears").value = true;
	}
	if (document.querySelector(`${formName} ${formName}-destination-africa`).checked) {
		document.querySelector("#interestinAfrica").value = true;
	}
	if (document.querySelector(`${formName} ${formName}-destination-galapagos`).checked) {
		document.querySelector("#interestinGalapagos").value = true;
	}
	if (document.querySelector(`${formName} ${formName}-destination-northern`).checked) {
		document.querySelector("#interestinAlaskaNorth").value = true;
	}
	if (document.querySelector(`${formName} ${formName}-destination-central-america`).checked) {
		document.querySelector("#interestinCentralAmerica").value = true;
	}
	if (document.querySelector(`${formName} ${formName}-destination-national-parks`).checked) {
		document.querySelector("#interestinUSNatlParks").value = true;
	}
	if (document.querySelector(`${formName} ${formName}-destination-asia-pacific`).checked) {
		document.querySelector("#interestinAsiaPacific").value = true;
	}
	if (document.querySelector(`${formName} ${formName}-destination-south-america`).checked) {
		document.querySelector("#interestinSouthAmerica").value = true;
	}
	if (document.querySelector(`${formName} ${formName}-destination-europe`).checked) {
		document.querySelector("#interestinEurope").value = true;
	}
	if (document.querySelector(`${formName} ${formName}-destination-antarctica`).checked) {
		document.querySelector("#interestinPolar").value = true;
	}
	if (document.querySelector(`${formName} ${formName}-interest-general-nature`).checked) {
		document.querySelector("#interestinGeneralNature").value = true;
	}
	if (document.querySelector(`${formName} ${formName}-interest-nature-photography`).checked) {
		document.querySelector("#interestinPhoto").value = true;
	}
	if (document.querySelector(`${formName} ${formName}-interest-family`).checked) {
		document.querySelector("#interestinFamily").value = true;
	}
	if (document.querySelector(`${formName} ${formName}-interest-hiking-kayaking`).checked) {
		document.querySelector("#interestinActive").value = true;
	}
	if (document.querySelector(`${formName} ${formName}-interest-cruising`).checked) {
		document.querySelector("#interestinCruises").value = true;
	}
	if (document.querySelector(`${formName} ${formName}-interest-custom`).checked) {
		document.querySelector("#interestinCustom").value = true;
	}

	// Travel Frequency
	var travelFrequency = document.querySelectorAll(`${formName} [name='travel-frequency']`);
	travelFrequency.forEach((radio) => {
		if (radio.checked) {
			document.querySelector("#temp15CatTravelFrequency").value = radio.value;
		}
	});
}

export function initMarketoFormOptins() {
	document.querySelector("#optInNHA").value = true;
	document.querySelector("#optInWebinar").value = true;
	document.querySelector("#optInStories").value = true;
	document.querySelector("#optInSurveys").value = true;
	document.querySelector("#optInTripDrips").value = true;
	document.querySelector("#optInSalesperson").value = true;
}

export function toggleButtonDisabled(button) {
	button.setAttribute("disabled", "true");
	setTimeout(function () {
		button.removeAttribute("disabled");
	}, 15000);
}

export function isThisSpam(formName) {
	var fields = ["first_name", "last_name"];
	for (const field of fields) {
		let target = `${formName}-${field}`;
		var spamCheck = document.querySelector(`${formName} ${target}`).value;
		if (spamCheck.match(/[!@#$%^&*(),.?":{}|<>]/g)) {
			return true;
		}
	}
}

export function validateForm(formName) {
	var invalidCount = $(formName).find(":invalid").length;
	if (invalidCount > 0) {
		$(formName).find(":invalid").addClass("js-invalid");
		return false;
	} else {
		return true;
	}
}

export function setRequiredFields(formName) {
	$(formName).find("[js-required]").attr("required", "");
}

export function unSetRequiredFields(formName) {
	$(formName).find("[js-required]").removeAttr("required");
}

// Disable sibling(ish) checkboxes after x checkbox is checked
export function limitCheckboxes(form) {
	let arrayOfCheckboxLists = document.querySelectorAll(`${form} [js-limit]`); // sort of vague
	arrayOfCheckboxLists.forEach((checkboxList) => {
		let max = parseInt(checkboxList.getAttribute("js-limit"), 10);
		let arrayOfCheckboxes = checkboxList.querySelectorAll("input[type='checkbox']");
		let totalChecked = 0;

		arrayOfCheckboxes.forEach((checkbox) => {
			checkbox.addEventListener("change", () => {
				if (checkbox.checked) {
					totalChecked++;
				} else {
					totalChecked--;
				}
				if (totalChecked > max) {
					checkbox.checked = false;
					totalChecked--;
				}
			});
		});
	});
}

export function updateFormButtonText(formName) {
	var originalButtonText = $(formName).find(".js-button-submit").text();
	$(formName).find(".js-button-submit").prop("disabled", "true");
	setTimeout(function () {
		$(formName).find(".js-button-submit").text("Saving ...");
	}, 500);
	setTimeout(function () {
		$(formName).find(".js-button-submit").text("Thank You!");
	}, 2000);
	setTimeout(function () {
		$(formName).find(".js-button-submit").text(originalButtonText);
		$(formName).find(".js-button-submit").prop("disabled", "");
	}, 5000);
}
