import { isWistiaVideoPlaying } from "../../components/video-wistia/_script.js";

export function isAdmin() {
	const ensoElement = document.querySelector("[enso]");
	const ensoElementAttribute = ensoElement.getAttribute("enso");
	return Boolean(ensoElementAttribute);
}

export function showForAdmin() {
	if (!isAdmin()) return;

	if (window.matchMedia("(max-width : 768px)").matches) return;

	const elements = document.querySelectorAll("[js-show-for-admin]");
	for (const element of elements) {
		const getDisplayType = element.getAttribute("js-show-for-admin");
		element.classList.remove("hide");
		element.style.display = `${getDisplayType}`; // to pick up edge cases during migration
	}
}

export function cleanupAnchorTags() {
	// Remove a tag if href is missing or undefined
	$("a").each(function () {
		if ($(this).attr("href") == "" || $(this).attr("href") == "undefined") {
			$(this).remove();
		}
	});
}

// Check all a links to find target = _blank    see above
export function cleanupAnchorTagsBlankTarget() {
	document.querySelectorAll("a").forEach(function (link) {
		if (!link.hasAttribute("href")) link.remove();

		const linkHref = link.getAttribute("href");

		// Add _blank to all links that go to Monster Campaigns so they open on top of our pages instead of being redirected to Monsty
		if (linkHref && linkHref.includes("https://app.monstercampaigns.com/")) {
			link.setAttribute("target", "_blank");
			return;
		}

		// Remove all _blank from a tags on mobile and tablet
		if (document.documentElement.clientWidth < 1025) {
			link.setAttribute("target", "_self");
		}
	});
}

export function pushEvent(item, action, label) {
	try {
		dataLayer.push({
			event: item,
			action: action,
			label: label
		});
		console.log("Success: Pushed Event via data layer:", item, action, label);
	} catch (error) {
		console.log("Failed: Pushed Event:", item, action, label, "-", error);
	}
}

export function pushEventFacebook() {
	try {
		fbq("track", "Lead");
	} catch (error) {
		console.log("Failed : pushEventFacebook");
	}
}

export function pushEventBing() {
	try {
		window.uetq = window.uetq || [];
		window.uetq.push({
			ec: "Lead",
			ea: "Catalog_Request"
		});
	} catch (error) {
		// console.log("Failed : pushEventBing");
	}
}

// Function: get user's device size. This function is intentionally duplicated here to be available for global use. Second instance lives in resize-img.js
export function getBrowserDevice() {
	if (window.matchMedia("(max-width : 767px)").matches) return "mobile";
	if (window.matchMedia("(min-width : 768px) AND (max-width : 1023px)").matches) return "tablet";
	if (window.matchMedia("(min-width : 1024px)").matches) return "desktop";
	return "unsure";
}

// EVENT - Check for country local storage, set if not already set
export async function saveUserCountryToLocalStorage() {
	let userCountry = localStorage.getItem("country");
	if (userCountry === null) {
		userCountry = await setUserCountry();
	}
}

// set user country in local storage
async function setUserCountry() {
	try {
		let response = await fetch("https://ipinfo.io/json?token=5ddc6962f801d5");
		let data = await response.json();
		let userCountry = data.country || "unknown";
		localStorage.setItem("country", userCountry);
		return userCountry;
	} catch (err) {
		console.error("Failed to fetch user country.", err);
		return "unknown"; // Return a default value in case of an error
	}
}

// Function: check if any modal is open
export function checkForOpenModals() {
	const isOpen = sessionStorage.getItem("modalOpen") === "true";
	return isOpen;
}

// Function: check if a wistia video is playing
export function checkIfWistiaVideoPlaying() {
	const wistiaVideo = document.querySelector(".js-video");
	if (wistiaVideo && isWistiaVideoPlaying() !== undefined) {
		return true;
	} else {
		return false;
	}
}

// vanilla wrapper to get cookie value
export function getCookie(name) {
	let value = `; ${document.cookie}`;
	let parts = value.split(`; ${name}=`);
	if (parts.length === 2) return parts.pop().split(";").shift();
}

// Utility to copy text to clipboard
export async function copyToClipboard(val) {
	try {
		await navigator.clipboard.writeText(val);
	} catch (err) {
		console.error("Failed to copy: ", err);
	}
}

// Utility to track browser size for Google Analytics -- are we using this?
export function browserSize() {
	let width = window.innerWidth || document.body.clientWidth;
	let height = window.innerHeight || document.body.clientHeight;
	let size = width + "x" + height;

	let browserSizeCookie = Cookies.get("browserSize");
	if (browserSizeCookie === "blocked" || browserSizeCookie == null || browserSizeCookie != size) {
		browserSizeCookie = size;
		Cookies.set("browserSize", size, {
			expires: 365
		});
		pushEvent("Browser Size", "Range", size);
	}
}

// Function: show hero photo credit if name has been entered in CMS
export function showHeroPhotoCredit() {
	const photoCredit = document.querySelector("[js-hero-photo-credit]");
	if (photoCredit) {
		const creditText = photoCredit.textContent.trim();
		if (creditText.length > 2) {
			photoCredit.classList.remove("hide");
		} else {
			photoCredit.classList.add("hide");
		}
	}
}

//  Need to run on page load but not a huge priority
export function setFavIcon() {
	const favicon = document.querySelector("[js-set-favicon]");
	const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
	if (!favicon) return;
	function updateFavicon() {
		favicon.setAttribute("href", mediaQuery.matches ? "/assets/images/icons/favicon-dark-mode.png" : "/assets/images/icons/favicon-light-mode.png");
	}
	updateFavicon();
	mediaQuery.addEventListener("change", updateFavicon);
}

// Track social icon clicks for GA
export function trackSocialClicks(links, area) {
	let socialLinks = document.querySelectorAll(`[${links}]`);
	socialLinks.forEach(function (link) {
		link.addEventListener("click", function () {
			let platform = link.getAttribute(`${links}`);
			pushEvent("Clicks", `Social ${area}`, `${platform}`);
		});
	});
}

// Functions: check if elements exist
const ensureElementExists = (elementSelector, limit) =>
	new Promise((resolve, reject) => {
		let count = 0;
		function waitForElement() {
			const element = document.querySelector(elementSelector);
			if (element) return resolve(element);
			if (limit && count > limit) return false;
			count += 1;
			setTimeout(waitForElement, 100);
		}
		waitForElement();
	});

export const waitUntilElementExists = async (elementSelector, limit) => {
	const response = await ensureElementExists(elementSelector, limit);
	return response;
};

export function addEnsoExample(selector) {
	const element = document.querySelector(selector);
	const elementText = element.innerText;

	if (element && elementText === "") element.classList.add("js-enso-example");
}

export function removeMissingImage() {
	const images = document.querySelectorAll("[js-remove-missing-image]");
	images.forEach((img) => {
		if (img.naturalWidth === 0) {
			img.remove();
		}
	});
}
