import { pushEvent } from "../../../assets/js/utilities.js";
import { toggleAmbientVideo } from "../../../components/hero/_video.js";

// does not make sense that this is in this folder
// split for mobile and desktop
export function mobileSiteNavHandler() {
	const mobileNavToggleLinks = document.querySelectorAll("[js-click-toggle]"); // this attribute also exists on modal close buttons
	const modalReset = document.querySelector("#modal-reset");

	mobileNavToggleLinks.forEach((link) => {
		link.addEventListener("click", function () {
			let targetElementName = link.getAttribute("js-click-toggle");
			let targetElementNameToTitle = targetElementName.charAt(0).toUpperCase() + targetElementName.slice(1);
			if (targetElementName) {
				pushEvent("Misc", `${targetElementNameToTitle} Mobile Nav`, "Clicked");
			}
			modalReset.setAttribute("modal-toggle", targetElementName);
			sessionStorage.setItem("modalOpen", false);

			toggleAmbientVideo(true);
		});
	});
}
