// Function : Smarty : Select Address
export function selectAddress(streetVal, cityVal, stateVal, zipVal, smarty) {
	let userCountry = localStorage.getItem("country") || "unknown";
	if (userCountry !== "US") return;

	smarty.streets.forEach((street) => {
		street.value = streetVal;
	});
	smarty.cities.forEach((city) => {
		city.value = cityVal;
	});
	smarty.states.forEach((state) => {
		state.value = stateVal;
	});
	smarty.zips.forEach((zip) => {
		zip.value = zipVal;
	});
	smarty.countries.forEach((country) => {
		country.value = "United States";
	});
	emptyMenu(smarty);
}

// Function : Empty Element
export function emptyMenu(smarty) {
	smarty.menus.forEach((menu) => {
		var children = Array.prototype.slice.call(menu.childNodes);
		children.forEach(function (child) {
			menu.removeChild(child);
		});
	});
}

// Function : Smarty : Build Menu
export function buildAddressMenu(suggestions, smarty) {
	emptyMenu(smarty);
	suggestions.forEach(function (suggestion) {
		smarty.menus.forEach((menu) => {
			let div = document.createElement("div");
			div.setAttribute("smarty", "selector");
			div.className = "js-smarty-selector";
			div.innerHTML = `<strong>${suggestion.street_line}</strong> ${suggestion.city}, ${suggestion.state} ${suggestion.zipcode}`;
			div.addEventListener("click", () => selectAddress(suggestion.street_line, suggestion.city, suggestion.state, suggestion.zipcode, smarty));
			menu.appendChild(div);
		});
	});
}

export function initSmarty(form) {
	let smarty = {};
	// Create Object : Smarty : Selectors
	smarty.menus = document.querySelectorAll(`${form} .js-smarty-menu`);
	smarty.streets = document.querySelectorAll(`${form} .js-smarty-street`);
	smarty.suites = document.querySelectorAll(`${form} .js-smarty-street-2`);
	smarty.cities = document.querySelectorAll(`${form} .js-smarty-city`);
	smarty.states = document.querySelectorAll(`${form} .js-smarty-state`);
	smarty.zips = document.querySelectorAll(`${form} .js-smarty-zip`);
	smarty.countries = document.querySelectorAll(`${form} .js-smarty-country`);

	function suggestNow(street, smarty) {
		if (street.value.length < 4) {
			return;
		}
		if (street.value) {
			smartySuggestions(street.value, smarty);
		}
	}

	// Function : Smarty : Ajax Post to Smarty Streets
	function smartySuggestions(search, smarty) {
		let ref = `${location.protocol}//${window.location.hostname}`;
		fetch(`https://us-autocomplete-pro.api.smartystreets.com/lookup?key=91583922427951116&search=${search}&max_results=5`, {
			method: "GET",
			headers: {
				host: "us-autocomplete-pro.api.smartystreets.com",
				referer: ref
			}
		})
			.then(function (response) {
				return response.json();
			})
			.then(function (data) {
				if (data.suggestions) {
					buildAddressMenu(data.suggestions, smarty);
				} else {
					emptyMenu(smarty);
				}
			})
			.catch(function (err) {
				console.warn("Something went wrong.", err);
			});
	}

	// Event : Smarty : Handle typing into street address
	smarty.streets.forEach((street) => {
		var current = -1;

		street.addEventListener(
			"keyup",
			_.throttle(function (event) {
				if (street.value.length < 4 || street.value.length > 25) {
					return;
				}
				if (event.keyCode === 38 || event.keyCode === 40 || event.keyCode === 13) {
					return;
				}
				suggestNow(street, smarty);
			}, 1250)
		);

		street.addEventListener("keyup", function (event) {
			if (street.value.length < 4) {
				return;
			}
			if (event.keyCode === 38) {
				var checkExist = setInterval(function () {
					if (street.nextElementSibling.hasChildNodes() === true) {
						clearInterval(checkExist);
					}
				}, 100);
				if (street.nextElementSibling.hasChildNodes() === true) {
					if (current === 0) {
						return;
					}
					street.nextElementSibling.children[current].classList.remove("focus");
					current--;
					street.nextElementSibling.children[current].classList.add("focus");
				}
			} else if (event.keyCode === 40) {
				var checkExist = setInterval(function () {
					if (street.nextElementSibling.hasChildNodes() === true) {
						clearInterval(checkExist);
					}
				}, 100);
				if (street.nextElementSibling.hasChildNodes() === true) {
					if (current === street.nextElementSibling.children.length - 1) {
						return;
					}
					if (current > -1) {
						street.nextElementSibling.children[current].classList.remove("focus");
					}
					current++;
					street.nextElementSibling.children[current].classList.add("focus");
				}
			} else if (event.keyCode === 13) {
				event.preventDefault();
				if (street.nextElementSibling.children[current].classList.contains("focus")) {
					var simulateClick = function (elem) {
						// Create our event (with options)
						var evt = new MouseEvent("click", {
							bubbles: true,
							cancelable: true,
							view: window
						});
						// If cancelled, don't dispatch our event
						var canceled = !elem.dispatchEvent(evt);
					};
					simulateClick(street.nextElementSibling.children[current]);
				}
				return false;
			} else {
				current = -1;
			}
		});
	});

	//  Event : Smarty : When suite or city field gains focus
	smarty.suites.forEach((suite) => {
		suite.addEventListener("focus", function () {
			emptyMenu(smarty);
		});
	});

	smarty.cities.forEach((city) => {
		city.addEventListener("focus", function () {
			emptyMenu(smarty);
		});
	});
}
