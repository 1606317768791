// Import dependencies:
// - List of countries
// - Helper functions for formatting, validation, and interaction with Marketo forms
import { countryList } from "../../../components/lists/country-list/_country-list.js";
import { initSmarty } from "../../../assets/js/smartypants.js";
import { setOptsOnMarketoForm, setSMSOptsOnMarketoForm, isSMSOptinChecked, isFormValid, isNewsletterChecked, initJsFormatPhone, initJsFormatName, setNewsletterCheckbox, specificTarget, mktoButtonClick, pushUtmToMarketoForm, initMarketoFormBasics, initMarketoFormCatalogBasic, initMarketoFormCatalogExtra, initMarketoFormOptins, toggleButtonDisabled, isThisSpam, validateForm, setRequiredFields, unSetRequiredFields, limitCheckboxes, updateFormButtonText } from "../../../assets/js/global-form-functions.js";
import { pushEvent, pushEventFacebook, pushEventBing, saveUserCountryToLocalStorage } from "../../../assets/js/utilities.js";
import { toggleAmbientVideo } from "../../../components/hero/_video.js";

// Asynchronous function to handle the catalog modal form interactions
export async function modalCatalog() {
	// Save the user's country to local storage (assumes an async operation, e.g., API call)
	await saveUserCountryToLocalStorage();

	// Retrieve the user's country from local storage, default to "unknown" if not set
	let userCountry = localStorage.getItem("country") || "unknown";

	// Store the current page URL for future reference
	let pageURL = document.URL;

	// Initialize the catalog object to hold various form elements and properties
	let catalog = new Object();
	let enewsChecked, smsChecked;
	catalog.formName = "#form-catalog"; // Form selector
	catalog.newsletter = false; // Newsletter subscription status
	catalog.smsElementParent = document.querySelector(".js-sms-checkbox-catalog"); // Parent element for SMS checkbox
	catalog.smsElement = document.querySelector("[js-catalog-sms]"); // SMS checkbox element
	catalog.phoneElementInput = document.querySelector("[js-catalog-phone-toggle]"); // Phone input element
	catalog.phoneElementLabel = document.querySelector('[for~="form-catalog-phone"]'); // Phone label element
	catalog.locationSelector = "js-catalog-location"; // Selector for location elements
	catalog.locationFirstElement = document.querySelector(`[${catalog.locationSelector}]`); // First location element
	catalog.locationAllElements = document.querySelectorAll(`[${catalog.locationSelector}]`); // All location elements
	catalog.locationValue = "Unknown"; // Default location value
	catalog.firstInput = document.querySelector("[catalog-form-label]"); // First input field in the form

	// Elements for different form screens (required, optional, thank you)
	let catalogScreenRequired = document.querySelector(".js-catalog-required"),
		catalogScreenOptional = document.querySelector(".js-catalog-optional"),
		catalogScreenThankYou = document.querySelector(".js-catalog-thankyou");

	// Initialize phone and name formatting functions
	initJsFormatPhone();
	initJsFormatName();

	// Function to handle changes to the SMS checkbox
	function smsCheckbox() {
		catalog.smsElement.addEventListener("input", function () {
			if (this.checked) {
				// If SMS checkbox is checked, make the phone input required
				catalog.phoneElementInput.setAttribute("required", "");
				catalog.phoneElementLabel.setAttribute("catalog-form-label", "required");
			} else {
				// If unchecked, remove the required attribute
				catalog.phoneElementInput.removeAttribute("required");
				catalog.phoneElementLabel.setAttribute("catalog-form-label", "");
			}
		});
	}

	// Add event listener to all elements with the locationSelector attribute
	catalog.locationAllElements.forEach((element) => {
		element.addEventListener("click", function () {
			// Set a session storage flag indicating the modal is open
			sessionStorage.setItem("modalOpen", true);

			// Pause ambient video when modal is opened
			toggleAmbientVideo(false);

			// Populate country input fields with the user's country if known
			const countryInput = document.querySelectorAll(`${catalog.formName} .js-smarty-country`);
			if (userCountry !== "unknown") {
				countryInput.forEach((input) => {
					const country = countryList.filter((country) => userCountry === country.code);
					input.value = country[0].name;
				});
			}

			// Set newsletter checkbox for users in specific countries and show SMS checkbox if necessary
			if (userCountry === "US") {
				setNewsletterCheckbox();
				initSmarty(catalog.formName);
				catalog.smsElementParent.classList.remove("hide");
				smsCheckbox();
			} else if (userCountry === "IN" || userCountry === "AU") {
				setNewsletterCheckbox();
			}

			// Initialize the Marketo form if it has not been loaded
			if (checkMarketoForm() === false) {
				initMarketoForm();
			}

			// Update catalog location value based on the clicked element's attribute
			if (catalog.locationValue !== "URL") {
				catalog.locationValue = element.getAttribute(catalog.locationSelector);
			}

			// Push an event for tracking purposes
			pushEvent("Catalog", catalog.locationValue, "Open");

			// Focus on the first input field after a short delay
			setTimeout(() => {
				catalog.firstInput.focus();
			}, 1000);
		});
	});

	// Handle clicks on links that have a hash or lightbox-related URL
	let linksWithHash = document.querySelectorAll("a[href^='#']");
	let linksWithHashNormalized = [];
	linksWithHash.forEach((link) => {
		let href = link.getAttribute("href").toLowerCase();
		if (href.includes("#catalog")) {
			linksWithHashNormalized.push(link);
		}
	});
	let linksWithLightbox = document.querySelectorAll("a[href~='?lightbox=catalog']");
	let linksCombined = [...linksWithHashNormalized, ...linksWithLightbox];

	// If matching links exist, add click event listeners to them
	if (linksCombined.length > 0) {
		addClickEvents(linksCombined);
		function addClickEvents(list) {
			list.forEach((element) => {
				element.addEventListener("click", function (e) {
					e.preventDefault(); // Prevent default link behavior
					catalog.locationValue = "Link"; // Set location to "Link"
					catalog.locationFirstElement.click(); // Trigger the modal open event
				});
			});
		}
	}

	// Automatically open modal if URL contains specific hash or query parameters
	if (pageURL.indexOf("#catalog") > 0 || pageURL.indexOf("#Catalog") > 0 || window.location.search.match(/lightbox\=catalog/i)) {
		catalog.locationValue = "URL"; // Set location to "URL"
		catalog.locationFirstElement.click(); // Trigger the modal open event
	}

	// Show or hide the "Who Referred You?" input based on the selected referral option
	let referralOptions = document.querySelector(".js-show-hide-who-referred");
	let friendReferralInput = document.querySelector(".js-show-hide-me");

	referralOptions.addEventListener("input", function () {
		let referralChoice = referralOptions.value;
		if (referralChoice === "Friend") {
			friendReferralInput.classList.remove("hide");
		} else {
			friendReferralInput.classList.add("hide");
		}
	});

	// Event listener for submitting the required form
	document.querySelector(".js-catalog-button-required").addEventListener("click", function (e) {
		e.preventDefault(); // Prevent form submission
		postFormCatalog(); // Call form submission function
	});

	// Event listener for submitting the optional form
	document.querySelector(".js-catalog-button-optional").addEventListener("click", function (e) {
		e.preventDefault(); // Prevent form submission
		postFormOptional(); // Call form submission function
	});

	// Function to create a unique ID using a specific pattern
	function createUniqueID() {
		return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
			let r = (Math.random() * 16) | 0,
				v = c == "x" ? r : (r & 0x3) | 0x8;
			return v.toString(16);
		});
	}

	// Generate a unique ID for form tracking
	let uniqueFormId = createUniqueID();

	// Copy fields from the catalog form to the Marketo form
	function copyCatalogFieldsToMarketoForm() {
		let fieldsToCopyArray = document.querySelectorAll(`${catalog.formName} [mrkto-field]`);
		fieldsToCopyArray.forEach((field) => {
			let mrktoFieldSelector = field.getAttribute("mrkto-field");
			if (field.value) {
				document.querySelector(mrktoFieldSelector).value = field.value;
			}
		});
	}

	// Copy checkbox fields from the catalog form to the Marketo form
	function pushToMarketoCatalogOptional(formName) {
		let checkboxesToCopyArray = document.querySelectorAll(`${catalog.formName} [mrkto-checkbox]`);
		checkboxesToCopyArray.forEach((checkbox) => {
			let mrktoCheckboxSelector = checkbox.getAttribute("mrkto-checkbox");
			if (checkbox.checked) {
				document.querySelector(mrktoCheckboxSelector).value = checkbox.value;
			}
		});

		let travelFrequency = document.querySelectorAll(`${formName} [name='travel-frequency']`);
		travelFrequency.forEach((radio) => {
			if (radio.checked) {
				document.querySelector("#temp15CatTravelFrequency").value = radio.value;
			}
		});
	}

	// Function to handle the submission of the required form
	function postFormCatalog() {
		let retryLimit = 3, // Max number of retries for form submission
			retryAttempt = 0;

		// Validate the form, exit if invalid
		let isValid = isFormValid(catalog.formName);
		if (isValid === false) {
			return;
		}

		// Check if the form is spam, exit if true
		if (isThisSpam(catalog.formName) === true) {
			return;
		}

		// Disable the submit button
		toggleButtonDisabled(document.querySelector(".js-catalog-button-required"));

		// Retry initializing the Marketo form if not loaded
		if (checkMarketoForm() === false && retryAttempt < retryLimit) {
			initMarketoForm(function () {
				retryAttempt++;
				postFormCatalog();
			});
		}

		// Submit the form using Marketo
		MktoForms2.whenReady(function () {
			enewsChecked = isNewsletterChecked(catalog.formName);
			smsChecked = isSMSOptinChecked(catalog.formName);

			pushUtmToMarketoForm(); // Add UTM parameters to the Marketo form
			document.querySelector("#catalogOptionalFieldIndicator").value = false;
			document.querySelector("#requestedAt").value = document.URL;
			document.querySelector("#LeadSource").value = "www.nathab.com";
			document.querySelector("#uniqueVisitorID").value = uniqueFormId;
			document.querySelector("#temp").value = "CatRequestIsTrue";
			document.querySelector("#sourceDetail").value = "Catalog Request";
			document.querySelector("#recentConversionAction").value = "Catalog Request";
			document.querySelector("#referringWebsite").value = utmObject.referringWebsite || "";
			document.querySelector("#isCatalogRequested").value = true;

			// Copy form fields to Marketo form
			copyCatalogFieldsToMarketoForm(catalog.formName);

			// Set Marketo form options for email and SMS signups
			if (enewsChecked === true) {
				document.querySelector("#isEmailSignup").value = true;
				setOptsOnMarketoForm();
			}
			if (smsChecked === true) {
				document.querySelector("#isSMSSignup").value = true;
				setSMSOptsOnMarketoForm();
			}

			// Submit the Marketo form
			marketoSubmitFormCatalog();

			let optins = "";

			// Set form options for email and SMS signups, if selected
			if (isNewsletterChecked(catalog.formName) === true) {
				document.querySelector("#isEmailSignup").value = true;
				optins += " eNews,";
				setOptsOnMarketoForm();
			}

			if (isSMSOptinChecked(catalog.formName) === true) {
				document.querySelector("#isSMSSignup").value = true;
				optins += " SMS";
				setSMSOptsOnMarketoForm();
			}

			// Set optins string for analytics event
			optins = optins || " no optins";
			optins = optins.endsWith(",") ? optins.slice(0, -1) : optins;

			// Push event to analytics platforms
			let pushEventString = `Submitted Page 1 w/${optins}`;
			pushEvent("Catalog", catalog.locationValue, pushEventString);

			// Push events to analytics platforms
			pushEventFacebook();
			pushEventBing();

			// Limit the number of checkboxes that can be selected
			limitCheckboxes(catalog.formName);

			// Show the optional form screen after a delay
			setTimeout(() => {
				catalogScreenRequired.classList.add("hide");
				catalogScreenOptional.classList.remove("hide");
			}, 2750);
		});
	}

	// Function to handle the submission of the optional form
	function postFormOptional() {
		let retryLimit = 3, // Max number of retries for form submission
			retryAttempt = 0;
		let optins = "";

		// Retry initializing the Marketo form if not loaded
		if (checkMarketoForm() === false && retryAttempt < retryLimit) {
			initMarketoForm(function () {
				retryAttempt++;
				postFormOptional();
			});
		}

		// Disable the submit button
		toggleButtonDisabled(document.querySelector(".js-catalog-button-optional"));

		// Set values for various Marketo form fields
		document.querySelector("#catalogOptionalFieldIndicator").value = true;
		document.querySelector("#requestedAt").value = document.URL;
		document.querySelector("#LeadSource").value = "www.nathab.com";
		document.querySelector("#uniqueVisitorID").value = uniqueFormId;
		document.querySelector("#temp").value = "CatRequestIsTrue";
		document.querySelector("#sourceDetail").value = "Catalog Request";
		document.querySelector("#recentConversionAction").value = "Catalog Request";
		document.querySelector("#referringWebsite").value = utmObject.referringWebsite || "";
		document.querySelector("#isCatalogRequested").value = true;

		// Copy form fields to Marketo form
		copyCatalogFieldsToMarketoForm(catalog.formName);

		// Push UTM parameters to the Marketo form
		pushUtmToMarketoForm();

		// Copy optional form fields to Marketo form
		pushToMarketoCatalogOptional(catalog.formName);

		// Set form options for email and SMS signups, if selected
		if (isNewsletterChecked(catalog.formName) === true) {
			document.querySelector("#isEmailSignup").value = true;
			optins += " eNews,";
			setOptsOnMarketoForm();
		}

		if (isSMSOptinChecked(catalog.formName) === true) {
			document.querySelector("#isSMSSignup").value = true;
			optins += " SMS";
			setSMSOptsOnMarketoForm();
		}

		// Set optins string for analytics event
		optins = optins || " no optins";
		optins = optins.endsWith(",") ? optins.slice(0, -1) : optins;

		// Push event to analytics platforms
		let pushEventString = `Submitted Page 2`;
		pushEvent("Catalog", catalog.locationValue, pushEventString);

		// Submit the Marketo form
		marketoSubmitFormCatalog();
		pushEventFacebook();
		pushEventBing();

		// Show the thank you screen after a delay
		setTimeout(function () {
			catalogScreenOptional.classList.add("hide");
			catalogScreenThankYou.classList.remove("hide");
			catalogScreenThankYou.scrollTop = 0;
		}, 3000);
	}

	// Function to submit the Marketo form
	function marketoSubmitFormCatalog() {
		if (checkMarketoForm() === false) {
			pushEvent("Marketo", "Form", "Not Loaded during marketoSubmitForm()");
		}

		// When Marketo form is ready, submit it
		MktoForms2.whenReady(function (form) {
			form.submit();
		});
	}
}
