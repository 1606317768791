export function isDialogSupported() {
	// Directly check for the existence of HTMLDialogElement in the window
	if (!window.HTMLDialogElement) return false;

	// Check if 'show' and 'close' methods are part of the HTMLDialogElement's prototype
	const dialogPrototype = window.HTMLDialogElement.prototype;

	// Return boolean
	return typeof dialogPrototype.show === "function" && typeof dialogPrototype.close === "function";
}
