// global components
import { saveUserCountryToLocalStorage } from "../../assets/js/utilities.js";
import { mobileSiteNavHandler } from "../../components/masthead/nav/_script.js";
import { modalCatalog } from "../../components/modal/catalog/_script.js";
import { modalEnews } from "../../components/modal/enews/_script.js";
import { modalYoutube } from "../../components/modal/youtube/_script.js";
import { ebookCTAs } from "../../components/modal/eBook/_script.js";
import { setFavIcon } from "../../assets/js/utilities.js";
import { trackSocialClicks } from "../../assets/js/utilities.js";
import { cleanupAnchorTags, cleanupAnchorTagsBlankTarget } from "../../assets/js/utilities.js";
import { browserSize } from "../../assets/js/utilities.js";

// global components try/catch
try {
	saveUserCountryToLocalStorage();
} catch (error) {
	console.error(error);
}

try {
	mobileSiteNavHandler();
} catch (error) {
	console.error(error);
}

try {
	modalEnews();
} catch (error) {
	console.error(error);
}

try {
	modalCatalog();
} catch (error) {
	console.error(error);
}

try {
	modalYoutube();
} catch (error) {
	console.error(error);
}

try {
	ebookCTAs();
} catch (error) {
	console.error(error);
}

try {
	cleanupAnchorTags();
	cleanupAnchorTagsBlankTarget();
} catch (error) {
	console.error(error);
}

try {
	browserSize();
} catch (error) {
	console.error(error);
}

try {
	trackSocialClicks("js-social-link-mega", "Menu");
	trackSocialClicks("js-social-link-footer", "Footer");
} catch (error) {
	console.error(error);
}

try {
	setFavIcon();
} catch (error) {
	console.error(error);
}
