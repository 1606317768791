import { checkForOpenModals } from "../../assets/js/utilities.js";

export function observeAmbientVideo() {
	sessionStorage.setItem("modalOpen", false);

	// select it
	const videos = document.querySelectorAll("[js-ambient-video]");

	// check it
	if (videos.length === 0) return;

	// func call to assign active video, return active video element
	let activeVideo = assignActiveAmbientVideo(videos);

	// resize event listener to call func to reassign active video
	window.addEventListener("resize", () => {
		activeVideo = assignActiveAmbientVideo(videos);
	});

	// visibility event listener to call func to pause video when tab is not active
	document.addEventListener("visibilitychange", () => {
		handleVisibilityChange(activeVideo);
	});

	// create an observer instance
	let observer = new IntersectionObserver(playOrPauseAmbientVideo, {
		threshold: 0.5 // Adjust as necessary
	});

	// observe active video
	if (activeVideo) observer.observe(activeVideo);
}

// play or pause based on tab visibility
function handleVisibilityChange(activeVideo) {
	if (checkForOpenModals()) return;

	toggleAmbientVideo(!document.hidden, activeVideo);
}

// find the active video
function assignActiveAmbientVideo(videos) {
	const isMobile = window.matchMedia("(orientation: portrait)").matches;

	const activeVideo = Array.from(videos).find((video) => {
		const deviceType = isMobile ? "mobile" : "desktop";
		const videoAttribute = video.getAttribute("js-ambient-video");

		if (videoAttribute !== deviceType) {
			toggleAmbientVideo(false, video); // Pause the video if it's not for the current device type
			return false;
		}

		return true;
	});

	if (activeVideo) activeVideo.classList.add("active");

	return activeVideo;
}

// play or pause based on intersection and tab visibility
function playOrPauseAmbientVideo(entries) {
	entries.forEach((entry) => {
		let isModalOpen = checkForOpenModals();
		if (entry.isIntersecting && !document.hidden && !isModalOpen) {
			toggleAmbientVideo(true, entry.target);
		} else {
			toggleAmbientVideo(false, entry.target);
		}
	});
}

function isAmbientVideoPlaying(videoElement) {
	return !videoElement.paused;
}

export function toggleAmbientVideo(shouldPlay, videoElement = null) {
	// If a specific video element is provided, use it
	if (videoElement) {
		shouldPlay ? videoElement.play() : videoElement.pause();
		return;
	}
	// Otherwise, find the active video
	const activeVideo = document.querySelector("[js-ambient-video].active");

	if (activeVideo) shouldPlay ? activeVideo.play() : activeVideo.pause();
}
