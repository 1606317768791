import { pushEvent, getCookie, getBrowserDevice, checkForOpenModals, checkIfWistiaVideoPlaying, waitUntilElementExists } from "../../assets/js/utilities.js";

export function olarkLoader() {
	let isChatBoxOpen = false;
	let modalAndVideoCheck = true;
	let isOperatorAvailable = "blocked"; // default state
	let chatCookieCheck;
	const browserDevice = getBrowserDevice();
	const chatCookieMaxAge = "43200"; // 43200 seconds = 12 hours
	const userActionAutopopDelay = 15000; // 15 seconds

	document.addEventListener("readystatechange", (event) => {
		if (event.target.readyState === "complete") {
			const chatButtonDesktop = document.querySelector(".js-chat-button-desktop");
			const chatButtonMobileBanner = document.querySelector(".js-chat-banner-mobile");
			const chatButtonMobile = document.querySelector(".js-chat-button-mobile");
			const chatMobileDrawerLabel = document.querySelector("[js-click-toggle~='contact']");
			const chatMobileDrawerToggle = document.querySelector("[js-close-chat]");

			olark("api.chat.onReady", function () {
				setTimeout(() => {
					isOperatorAvailable = checkChatStatus();
					autoPopChatAfterXSeconds(isOperatorAvailable); // function call for 90 second auto popup
					if (isOperatorAvailable !== "blocked" && browserDevice !== "mobile") {
						chatButtonDesktop.classList.remove("hide");
					} else if (isOperatorAvailable !== "blocked" && browserDevice == "mobile") {
						chatButtonMobileBanner.classList.remove("hide");
						chatButtonMobile.classList.remove("hide");
					}
				}, 2000);
				olark("api.box.shrink");
			});

			// For when the box is expanded either from a button click or operator initiated
			olark("api.box.onExpand", function () {
				isChatBoxOpen = true;
				if (browserDevice !== "mobile") {
					chatButtonDesktop.classList.add("hide");
				}
				if (browserDevice === "mobile") {
					chatMobileDrawerLabel.click();
					setTimeout(() => {
						olark("api.box.expand");
					}, 100);
				}

				// check if session has been set to remove consent message
				let showConsent = sessionStorage.getItem("showConsent");
				if (showConsent === "false") {
					const consentMessageDiv = document.querySelector(".olark-gdpr-consent-message");
					if (consentMessageDiv) {
						consentMessageDiv.remove();
					}
				}
				// When chat is opened, call function to decide if enews consent box is displayed and gets pre-checked
				shouldEmailConsentBeDisplayed();
			});

			olark("api.box.onShow", function () {
				isChatBoxOpen = true;
				chatButtonDesktop.classList.add("hide");
			});

			// For when the box is shrunk either from a button click
			olark("api.box.onShrink", function () {
				isChatBoxOpen = false;
				chatButtonDesktop.classList.remove("hide");
				chatButtonDesktop.classList.remove("animate__animated");

				if (browserDevice == "mobile") {
					chatMobileDrawerToggle.click();
				}
			});

			olark("api.box.onHide", function () {
				isChatBoxOpen = false;
				chatButtonDesktop.classList.remove("hide");
				chatButtonDesktop.classList.remove("animate__animated");

				if (browserDevice == "mobile") {
					chatMobileDrawerToggle.click();
				}
			});

			// For when the operator sends a message on mobile only
			olark("api.chat.onMessageToVisitor", function (event) {
				isOperatorAvailable = checkChatStatus();
				if (browserDevice === "mobile" && isOperatorAvailable) {
					olark("api.box.expand");
				}
			});

			// Callback if operator is available
			olark("api.chat.onOperatorsAvailable", function () {
				isOperatorAvailable = checkChatStatus();
				autoPopChatAfterXSeconds(isOperatorAvailable);
			});

			// Disappear consent button on first message to operator when chat is online
			olark("api.chat.onMessageToOperator", function (e) {
				const consentMessageDiv = document.querySelector(".olark-gdpr-consent-message");
				consentMessageDiv.remove();
				// set session so div is removed when user goes to a new page
				sessionStorage.setItem("showConsent", "false");
			});

			// CLICK LISTENERS
			// Add click listener to mobile button on contact drawer
			chatButtonMobile.addEventListener("click", function (e) {
				olark("api.box.expand");
			});

			// Add click listener to mobile banner
			chatButtonMobileBanner.addEventListener("click", function (e) {
				chatMobileDrawerLabel.click();
				setTimeout(() => {
					olark("api.box.expand");
				}, 100);
				pushEvent("Olark_Chat", "Button Click", "Mobile");
			});

			// Add click listener to desktop button
			chatButtonDesktop.addEventListener("click", function (e) {
				olark("api.box.expand");
				pushEvent("Olark_Chat", "Button Click", "Desktop");
			});

			// check for session value in case user went to different page before the 15 second timeout allowed chat to autopop after user action
			chatCookieCheck = cookieValueCheck("chatAutoPopupSession");
			if (chatCookieCheck > userActionAutopopDelay) {
				openChat(isOperatorAvailable);
				// set auto popup cookies to prevent more auto popups
				setAutoPopupCookie("chatAutoPopup", chatCookieMaxAge);
			}
		}
	});

	// MAIN FUNCTIONS FOR EMAIL CONSENT BOX
	function shouldEmailConsentBeDisplayed() {
		const consentMessageDiv = document.querySelector(".olark-gdpr-consent-message");
		const consentButton = document.querySelector(".olark-gdpr-consent-checkbox");

		if (consentButton && consentButton.classList.contains("olark-gdpr-consent-checked")) {
			consentMessageDiv.remove();
		}
		const onlineStartChatButton = document.querySelector(".olark-button.olark-survey-form-submit"); // when chat is online, this exists and email consent is displayed
		const offlineNextButton = document.querySelector(".olark-button.olark-survey-form-nav"); // when chat is offline, this exists and email consent is not displayed

		// when chat is online, listen for chat start button and call function to decide if enews consent gets checked and set cookies so no autopop happens
		if (onlineStartChatButton) {
			onlineStartChatButton.addEventListener("click", function () {
				preCheckConsent("online");
				setAutoPopupCookie("chatAutoPopup", chatCookieMaxAge);
				setAutoPopupCookie("chatAutoPopupBlock", chatCookieMaxAge);
			});
		}

		// when chat is offline, add click listener, call function to decide if enews consent gets checked and call next function
		if (offlineNextButton) {
			offlineNextButton.addEventListener("click", offlinePageTwo);
			preCheckConsent("offline");
		}
	}

	// Precheck email consent box for users in US, India, and Australia when chat is online and add privacy policy
	function preCheckConsent(chatStatus) {
		let consentButtonClass;
		let consentMessageDivClass;
		const privacyPolicy = '<a class="js-chat-button-privacy" href="https://www.nathab.com/privacy-policy/" target="_blank">Privacy Policy</a>';

		if (chatStatus === "online") {
			consentButtonClass = ".olark-gdpr-consent-checkbox";
			consentMessageDivClass = ".olark-gdpr-consent-message";
		}
		if (chatStatus === "offline") {
			consentButtonClass = ".olark-survey-form-input.olark-survey-form-checkbox";
			consentMessageDivClass = ".olark-survey-form-input-wrap";
		}

		waitUntilElementExists(consentButtonClass).then(countryCheck);
		waitUntilElementExists(consentMessageDivClass).then(insertPrivacyPolicy);

		function countryCheck() {
			let userCountry = localStorage.getItem("country") || "unknown";
			const consentButton = document.querySelector(consentButtonClass);
			if (userCountry === "US" || userCountry === "IN" || userCountry === "AU") {
				if (consentButton.checked === false) {
					consentButton.click();
				}
			}
		}

		function insertPrivacyPolicy() {
			const consentMessageDiv = document.querySelector(consentMessageDivClass);
			const privacyPolicyElement = document.querySelector(".js-chat-button-privacy");
			if (!privacyPolicyElement) {
				consentMessageDiv.insertAdjacentHTML("beforeend", privacyPolicy);
			}
		}
	}

	// When chat is offline, switch mailbox icon to eNews icon on page 2
	function offlinePageTwo() {
		document.querySelector(".olark-button").removeEventListener("click", offlinePageTwo);
		const mailboxIconClass = ".olark-survey-form-emoji-wrap svg";
		waitUntilElementExists(mailboxIconClass).then(iconSwitch);

		function iconSwitch() {
			const mailboxIcon = document.querySelector(mailboxIconClass);
			const enewsIcon = '<img class="js-chat-enews-icon" ignore-cdn src="https://cdn.filestackcontent.com/A6dTpd53SmIg0pBfJJhgAz/cache=expiry:31536002/https://www.nathab.com/uploaded-files/_global/thumbnail-enews-v11.png"></img>';
			const enewsIconElement = document.querySelector(".js-chat-enews-icon");
			if (!enewsIconElement) {
				mailboxIcon.insertAdjacentHTML("beforebegin", enewsIcon);
			}
		}
	}

	// MAIN FUNCTIONS TO AUTO POPUP CHAT
	// Initial 90 second chat auto popup
	function autoPopChatAfterXSeconds(isOperatorAvailable) {
		const autopopDelay = 90000; // 90 seconds
		// const autopopDelay = 15000; // 15 seconds for testing

		// auto popup chat only on desktop && if operator is online
		if (browserDevice !== "desktop" || isOperatorAvailable !== true) {
			return;
		}

		// autopop only if chat is online, autopop blocking cookie is not set, and at least 90 seconds have passed
		let chatCookieCheck = cookieValueCheck("chatAutoPopup");
		if (getCookie("chatAutoPopupBlock") === "true" || chatCookieCheck < autopopDelay) {
			return;
		}

		// set cookie if not already set
		if (chatCookieCheck === "none") {
			setAutoPopupCookie("chatAutoPopup", chatCookieMaxAge);
			return;
		}

		// if no modal is open and no video is playing, auto pop chat and set cookies
		modalAndVideoCheck = callOpenModalAndVideoFunctions();
		if (modalAndVideoCheck === false) {
			openChat(isOperatorAvailable);
			setAutoPopupCookie("chatAutoPopup", "0"); //remove (expire) cookie so other auto popup works
			setAutoPopupCookie("chatAutoPopupBlock", chatCookieMaxAge);
		}
	}
}

// User-action-initiated chat auto popup occurs after user submits Ask a Question, Send Us a Message, or downloads PDF forms or opens a date or pricing accordion
export function autoPopChatUserAction(inputID) {
	const inputDiv = document.querySelector(inputID);
	const bodyDiv = document.querySelector("body");
	const userActionAutopopDelay = 15000; // 15 seconds
	let modalInputDiv;
	let accordionInputDiv;

	// auto popup chat only on desktop && if operator is online
	let isOperatorAvailable = checkChatStatus();
	if (getBrowserDevice() !== "desktop" || isOperatorAvailable !== true) {
		return;
	}

	// assign variable whether modal or accordion to determine how chat auto popup is executed
	if (inputDiv.hasAttribute("accordion")) {
		accordionInputDiv = inputDiv;
	} else {
		modalInputDiv = inputDiv;
	}

	// if input that triggered function is an open modal, add click listener to know when it's closed
	if (modalInputDiv && modalInputDiv.checked) {
		bodyDiv.addEventListener("click", shouldChatAutoPop);
	}

	// if input that triggered function is an open accordion, call chat auto popup to open on top of accordion
	if (accordionInputDiv) {
		shouldChatAutoPop();
	}

	function shouldChatAutoPop() {
		const chatCookieMaxAge = "43200"; // 43200 seconds = 12 hours

		// don't auto popup chat if the form is still open
		if (modalInputDiv && modalInputDiv.checked) {
			return;
		}
		// don't auto popup chat if user action autopop has happened within 12 hours (both cookies have a value)
		let chatCookieCheck = cookieValueCheck("chatAutoPopup");
		if (chatCookieCheck !== "none" && getCookie("chatAutoPopupBlock") === "true") {
			return;
		}
		// don't auto popup chat if any modal is open or video is playing
		let modalAndVideoCheck = callOpenModalAndVideoFunctions();
		if (modalAndVideoCheck === true) {
			return;
		}

		if (modalInputDiv || (accordionInputDiv && accordionInputDiv.checked)) {
			// set session in case user goes to a new page before chat is auto popped
			sessionStorage.setItem("chatAutoPopupSession", Number(Date.now()));

			// 15 second delay
			setTimeout(function () {
				// need to recheck this in case modal or video opened after Thank You message closed and before chat is popped
				modalAndVideoCheck = callOpenModalAndVideoFunctions();
				if (modalAndVideoCheck === true) {
					bodyDiv.addEventListener("click", () => {
						modalAndVideoCloseTimerForAccordion(isOperatorAvailable);
					});
					return;
				}

				if (getCookie("chatAutoPopupBlock") === "true") return;

				openChat(isOperatorAvailable);
				if (chatCookieCheck === "none") {
					setAutoPopupCookie("chatAutoPopup", chatCookieMaxAge);
				}
				setAutoPopupCookie("chatAutoPopupBlock", chatCookieMaxAge);

				// remove listener on all accordions after the first one is opened and triggers chat auto popup
				if (accordionInputDiv && accordionInputDiv.checked) {
					removeAccordionClickListener();
				}
			}, userActionAutopopDelay);
		}
	}
}

// AUTO POPUP HELPER FUNCTIONS
// Check online/offline chat status
function checkChatStatus() {
	const chatButtonDesktop = document.querySelector(".js-chat-button-desktop");
	if (chatButtonDesktop.classList.contains("chatoff")) {
		return false;
	}
	const olarkTopBarText = document.querySelector(".olark-top-bar-text");
	if (!olarkTopBarText) {
		return "blocked"; // olark not rendered so likely blocking 3rd party cookies
	}

	if (olarkTopBarText.textContent.toLowerCase().includes("offline")) {
		return false;
	} else {
		return true;
	}
}

//  As a pre-condition to auto popping chat, call functions to check if any modal is open or a video is playing
export function callOpenModalAndVideoFunctions() {
	let isModalOpen = checkForOpenModals();
	let wistiaVideoPlayStatus = checkIfWistiaVideoPlaying();
	if (isModalOpen === false && wistiaVideoPlayStatus === false) {
		return false;
	} else {
		return true;
	}
}

// Remove click listeners on all accordions after one has been opened and triggered chat auto pop
function removeAccordionClickListener() {
	const datesAndPricingAccordions = document.querySelectorAll('[accordion="checkbox"]');
	datesAndPricingAccordions.forEach(function (accordion) {
		accordion.removeEventListener("click", accordionClickListener);
	});
}

// Timer function in case user has opened modal/video after accordion timer is set and before chat popup happens to pop chat after modal/video is closed
function modalAndVideoCloseTimerForAccordion(isOperatorAvailable) {
	const closeTimerDelay = 1500; // 1.5 second delay
	const bodyDiv = document.querySelector("body");

	const modalAndVideoCheck = callOpenModalAndVideoFunctions();
	if (modalAndVideoCheck === false) {
		setTimeout(function () {
			openChat(isOperatorAvailable);
			bodyDiv.removeEventListener("click", () => {
				modalAndVideoCloseTimerForAccordion(isOperatorAvailable);
			});
			removeAccordionClickListener(); // remove listener on all accordions after the first one is open and triggers chat auto popup
			let chatCookieCheck = cookieValueCheck();
			if (chatCookieCheck === "none") {
				setAutoPopupCookie("chatAutoPopup", chatCookieMaxAge);
			}
			setAutoPopupCookie("chatAutoPopupBlock", chatCookieMaxAge);
		}, closeTimerDelay);
	}
}

// UTILITY FUNCTIONS
// Utility function to check cookie or session
function cookieValueCheck(cookie) {
	let currentTime = Number(Date.now());
	let currentCookieValue;
	if (cookie === "chatAutoPopupSession") {
		currentCookieValue = sessionStorage.getItem("chatAutoPopupSession") || currentTime;
	} else {
		currentCookieValue = Number(getCookie(`${cookie}`) || currentTime);
	}
	let timeDiff = currentTime - currentCookieValue;
	if (cookie === "chatAutoPopupSession") {
		return timeDiff;
	}
	if (currentCookieValue === currentTime) {
		return "none";
	} else {
		return timeDiff;
	}
}

// Utility function to set cookie
function setAutoPopupCookie(cookie, expiration) {
	let currentTime = Number(Date.now());
	let autoPopupCookieValue;
	if (cookie === "chatAutoPopup") {
		autoPopupCookieValue = currentTime;
	}
	if (cookie === "chatAutoPopupBlock") {
		autoPopupCookieValue = "true";
	}
	document.cookie = `${cookie}=${autoPopupCookieValue}; path=/; domain=nathab.com; max-age=${expiration}`; // 43200 = 12 hour expiration, seconds
}

// Utility function to auto open chat, clear session, and push event
function openChat(isOperatorAvailable) {
	if (isOperatorAvailable !== true) return;
	olark("api.box.expand");
	pushEvent("Olark_Chat", "Auto Popup");
	sessionStorage.removeItem("chatAutoPopupSession");

	const chatButtonStartChatClass = ".olark-button.olark-survey-form-submit";
	waitUntilElementExists(chatButtonStartChatClass).then(chatStartClickListener);

	function chatStartClickListener() {
		const chatButtonStartChat = document.querySelector(chatButtonStartChatClass);
		chatButtonStartChat.addEventListener("click", function (e) {
			pushEvent("Olark_Chat", "Auto Popup", "Chat Started");
		});
	}
}
// Utility function to check whether chat box open
export function isChatOpen() {
	const olarkContainer = document.querySelector("#olark-container");
	let isChatBoxOpen = false;

	if (olarkContainer && !olarkContainer.classList.contains("olark-hidden")) {
		isChatBoxOpen = true;
	}

	return isChatBoxOpen;
}

// add click listener and auto popup chat when accordion is opened, gets called after dates accordion is dynamically built
export function autoPopupChatAccordionClickListener() {
	const datesAndPricingAccordions = document.querySelectorAll('[accordion="checkbox"]');
	datesAndPricingAccordions.forEach(function (accordion) {
		accordion.addEventListener("click", accordionClickListener);
	});
}

function accordionClickListener(event) {
	if (event.target.checked) {
		let accordionID = event.target.getAttribute("id");
		autoPopChatUserAction(`input[id="${accordionID}"]`); // NOW THAT OLARK IS WRAPPED IN FUNCTION, THIS ISN'T AVAILABLE FOR USE HERE
	}
}
