import { initJsFormatPhone, initJsFormatName, setNewsletterCheckbox, setOptsOnMarketoForm, isFormValid, pushUtmToMarketoForm, toggleButtonDisabled, isThisSpam } from "../../../assets/js/global-form-functions.js";
import { isSMSOptinChecked, setSMSOptsOnMarketoForm } from "../../../assets/js/global-form-functions.js";
import { pushEvent, pushEventFacebook, pushEventBing, saveUserCountryToLocalStorage } from "../../../assets/js/utilities.js";
import { toggleAmbientVideo } from "../../../components/hero/_video.js";

export async function modalEnews() {
	await saveUserCountryToLocalStorage();
	let userCountry = localStorage.getItem("country") || "unknown";

	let pageURL = document.URL;
	let enews = new Object();
	enews.formName = "#form-enews";
	enews.eventAction = "";
	enews.smsElementParent = document.querySelector(".js-sms-checkbox-enews");
	enews.smsElement = document.querySelector("[js-enews-sms]");
	enews.phoneElementInput = document.querySelector("[js-enews-phone-toggle]");
	enews.phoneElementLabel = document.querySelector('[for~="form-enews-phone"]');
	enews.locationSelector = "js-enews-location";
	enews.locationFirstElement = document.querySelector(`[${enews.locationSelector}]`);
	enews.locationAllElements = document.querySelectorAll(`[${enews.locationSelector}]`);
	enews.locationValue = "Unknown";
	enews.firstInput = document.querySelector("[enews-form-label]");

	let eNewsScreenRequired = document.querySelector(".js-enews-required"),
		eNewsScreenThankYou = document.querySelector(".js-enews-thankyou");

	initJsFormatPhone();
	initJsFormatName();

	// EVENT - Populate modal with email from inline enews input
	const populateEmail = () => {
		const inlineEmail = document.getElementById("enews-inline-email");
		const modalEmail = document.getElementById("form-enews-email");
		modalEmail.value = inlineEmail.value;
	};

	// Event - When SMS checkbox changes do stuff
	function smsCheckbox() {
		enews.smsElement.addEventListener("input", function () {
			if (this.checked) {
				enews.phoneElementInput.setAttribute("required", "");
				enews.phoneElementLabel.setAttribute("enews-form-label", "required");
			} else {
				enews.phoneElementInput.removeAttribute("required");
				enews.phoneElementLabel.setAttribute("enews-form-label", "");
				enews.phoneElementInput.classList.remove("js-invalid");
			}
		});
	}

	// EVENT - Add event listener to all elements with the locationSelector
	enews.locationAllElements.forEach((element) => {
		element.addEventListener("click", function (e) {
			sessionStorage.setItem("modalOpen", true);

			// pause ambient video when modal is opened
			toggleAmbientVideo(false);

			if (userCountry === "US") {
				setNewsletterCheckbox();
				enews.smsElementParent.classList.remove("hide");
				smsCheckbox();
			}
			if (checkMarketoForm() === false) {
				initMarketoForm();
			}
			if (enews.locationValue !== "URL") {
				enews.locationValue = element.getAttribute(enews.locationSelector);
			}
			pushEvent("eNews", enews.locationValue, "Open");

			setTimeout(() => {
				enews.firstInput.focus();
			}, 1000);
		});
	});

	enews.locationAllElements.forEach((el) => {
		if (el.getAttribute("js-enews-location") === "Bottom") {
			el.addEventListener("click", function (e) {
				populateEmail();
			});
		}
	});

	// EVENT - Check for #enews or in URL
	let linksWithHash = document.querySelectorAll("a[href^='#']");
	let linksWithHashNormalized = [];
	linksWithHash.forEach((link) => {
		let href = link.getAttribute("href").toLowerCase();
		if (href.includes("#enews")) {
			linksWithHashNormalized.push(link);
		}
	});

	let linksWithLightbox = document.querySelectorAll("a[href~='?lightbox=enews']");
	let linksCombined = [...linksWithHashNormalized, ...linksWithLightbox];
	addClickEvents(linksCombined);
	function addClickEvents(list) {
		list.forEach((element) => {
			element.addEventListener("click", function (e) {
				e.preventDefault();
				enews.locationValue = "Link";
				enews.locationFirstElement.click();
			});
		});
	}
	// EVENT - Check for #hash or ?lightbox=value in URL
	if (pageURL.indexOf("#enews") > 0 || pageURL.indexOf("#eNews") > 0 || window.location.search.match(/lightbox\=enews/i)) {
		enews.locationValue = "URL"; //default
		enews.locationFirstElement.click();
	}

	// EVENT - Handle submit of required form
	document.querySelector(".js-enews-button-required").addEventListener("click", function (e) {
		e.preventDefault();
		postFormEnews();
	});

	function setEnewsBasicsOnMarketoForm() {
		document.querySelector("#sourceDetail").value = enews.locationValue;
		document.querySelector("#requestedAt").value = document.URL;
		document.querySelector("#LeadSource").value = "www.nathab.com";
		document.querySelector("#recentConversionAction").value = "Email Sign-Up";
		document.querySelector("#referringWebsite").value = utmObject.referringWebsite || "";
	}

	function copyNewsletterFieldsToMarketoForm() {
		let fieldsToCopyArray = document.querySelectorAll(`${enews.formName} [mrkto-field]`);
		fieldsToCopyArray.forEach((field) => {
			let mrktoFieldSelector = field.getAttribute("mrkto-field");
			if (field.value) {
				document.querySelector(mrktoFieldSelector).value = field.value;
			}
		});
	}

	function postFormEnews() {
		let retryLimit = 3,
			retryAttempt = 0;

		// Validate form
		let isValid = isFormValid(enews.formName);
		if (isValid === false) {
			return;
		}

		// Check for spam
		if (isThisSpam(enews.formName) === true) {
			return;
		}

		toggleButtonDisabled(document.querySelector(".js-enews-button-required"));

		// Check if marketo form has been loaded, if not retry with limits
		if (checkMarketoForm() === false && retryAttempt < retryLimit) {
			initMarketoForm(function () {
				retryAttempt++;
				postFormEnews();
			});
		}

		MktoForms2.whenReady(function () {
			let optins = "";

			pushUtmToMarketoForm(); // function lives in global

			document.querySelector("#isEmailSignup").value = true;

			setEnewsBasicsOnMarketoForm(enews.formName);

			copyNewsletterFieldsToMarketoForm(enews.formName);

			setOptsOnMarketoForm();

			if (isSMSOptinChecked(enews.formName) === true) {
				document.querySelector("#isSMSSignup").value = true;
				optins += " SMS";
				setSMSOptsOnMarketoForm();
			}

			optins = optins || " no optins"; // if empty string, set to no optins
			let pushEventString = `Submitted w/${optins}`;

			pushEvent("eNews", enews.locationValue, pushEventString);

			marketoSubmitFormEnews();
			pushEventFacebook();
			pushEventBing();

			setTimeout(() => {
				eNewsScreenRequired.classList.add("hide");
				eNewsScreenThankYou.classList.remove("hide");
				eNewsScreenThankYou.scrollTop = 0;
			}, 2750);
		});
	}

	function marketoSubmitFormEnews() {
		if (checkMarketoForm() === false) {
			pushEvent("Marketo", "Form", "Not Loaded during marketoSubmitForm()");
		}

		MktoForms2.whenReady(function (form) {
			form.submit();
		});
	}
}
